<template>
  <!-- 新 - 首页 - 关于晟融安知 -->
  <div class="aboutShengrongAn">
    <headPage />
    <carousel />
    <div class="shell_2">
      <div class="shell">
        <p class="p_1">全过程数据资源体系建设服务</p>
        <p class="p_2" style="padding: 0 75px;">
          晟融数据通过构建多维数据容器，实现结构化与非结构化数据的集成，可推动全景、全息、全过程的数据应用，最终提供大数据应用全流程解决方案。
        </p>
        <div class="d_1">
          <img src="../../assets/loginIndex/aboutShengrongAn_1.png" alt="" />
          <p class="p_1">一个平台</p>
          <p class="p_2">
            基于信息化系统的数据工作平台建设，将客户的原始数据进行转化、清洗、规约形成多维数据仓库，通过SDAM完成数据应用的分析、挖掘。
          </p>
        </div>
        <div class="d_1">
          <img src="../../assets/loginIndex/aboutShengrongAn_2.png" alt="" />
          <p class="p_1">一套标准</p>
          <p class="p_2">
            建立业务标准、数据标准、过程标准、场地标准、服务标准等成熟的标准规范，为客户挖掘数据资源，建设标准化数据打好了坚实的基础。
          </p>
        </div>
        <div class="d_1">
          <img src="../../assets/loginIndex/aboutShengrongAn_3.png" alt="" />
          <p class="p_1">一个系统</p>
          <p class="p_2">
           构建数据应用核心系统，通过互联网应用、公共服务、数据分析报告、数据统计分析、管理者驾驶舱、决策辅助等应用，让数据发挥最大价值。
          </p>
        </div>
        <div class="d_1 d_1_1">
          <img src="../../assets/loginIndex/aboutShengrongAn_4.png" alt="" />
          <p class="p_1">一套体系</p>
          <p class="p_2">
            建立信息收集、数据仓库、数据变换、数据挖掘、业务流程变革等大数据资源开发应用体系。
          </p>
        </div>
        <img
          class="s_1"
          src="../../assets/loginIndex/aboutShengrongAn_5.png"
          alt=""
        />
        <p class="p_3">
         数据资源体系建设服务由数据标准、服务体系、工作平台和数据应用系统构成，该体系通过数据应用系统建立多维数据仓库，可为企事业单位提供数据和应用需求收集、存储、分析、共享等系统化服务，最终实现数据资源的最大价值。
        </p>
      </div>
    </div>
    <div class="shell_3">
      <div class="shell">
        <p class="p_1">大数据与互联网融合应用</p>
        <img
          class="s_1"
          src="../../assets/loginIndex/aboutShengrongAn_6.png"
          alt=""
        />
      </div>
    </div>
    <bottomPage />
  </div>
</template>
<script>
import headPage from "../wrongAuthenticatePage/headPage.vue";
import bottomPage from "./bottomPage.vue";
import carousel from "../wrongAuthenticatePage/carousel.vue";
export default {
  name: "wrongAuthenticatePage_aboutShengrongAn",
  components: {
    headPage,
    bottomPage,
    carousel,
  },
  data() {
    return {};
  },
  created() {
  },
  computed: {},
  methods: {
  
  },
};
</script>
<style lang="less" scoped>
.aboutShengrongAn {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .shell_2 {
    width: 100%;
    overflow: hidden;
    margin-top: 60px;
    .shell {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .p_2 {
        text-align: center;
        color: #5c6b84;
        font-size: 17px;
      }
      .d_1 {
        width: 23%;
        height: 288px;
        float: left;
        margin-right: 2%;
        margin-top: 40px;
        margin-bottom: 60px;
        border-radius: 6px;
        border: 1px solid #edf6ff;
        img {
          width: 48px;
          height: 48px;
          display: block;
          margin: auto;
          margin-top: 30px;
        }
        .p_1 {
          color: #3f4652;
          font-size: 20px;
          margin-top: 20px;
          font-weight: 500;
        }
        .p_2 {
          width: 86%;
          margin: auto;
          color: #5c6b84;
          font-size: 14px;
          margin-top: 16px;
          line-height: 20px;
          text-align: left;
        }
        &:hover {
          box-shadow: 0px 0px 38px 0px rgba(87, 174, 244, 0.16);
          cursor: pointer;
        }
      }
      .d_1_1 {
        margin-right: 0;
      }
      .s_1 {
        width: 1002px;
        height: 498px;
        display: block;
        margin: auto;
      }
      .p_3 {
        color: #5c6b84;
        font-size: 17px;
        margin-top: 30px;
        margin-bottom: 45px;
        line-height: 30px;
        text-align: left;
        text-indent: 2em;
        padding: 0 75px;
      }
    }
  }
  .shell_3 {
    width: 100%;
    overflow: hidden;
    padding: 60px 0;
    background: #f8fafd;
    .shell {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .s_1 {
        width: 100%;
        display: block;
        margin: auto;
      }
    }
  }
}
</style>
